import React from "react";
import { Carousel } from "react-bootstrap";
import NavBar from "../nav-bar/Nav";
import './landing-page.scss';

export default function Landing() {
    const slides = [
        {
            id: 'slideOne',
            heading: 'De-mystify enterprise cloud cyber security compliance data',
            caption: 'Extract meaningful data summaries from piles of complex raw compliance data elements that present an enterprise level view of cloud cyber risk posture for C-suite and board level discussions.'
        },
        {
            id: 'slideTwo',
            heading: 'Consistent cyber security compliance reports for cyber insurance applications',
            caption: 'Incorporate consistent cyber security compliance reports as a part of cyber insurance applications for streamlined policy evaluation, pricing and underwriting'
        },
        {
            id: 'slideThree',
            heading: 'Facilitate transparency in vendor risk management',
            caption: 'Gain non-intrusive visibility to cloud cyber health summary reports of your vendors via authorized shared dashboard level compliance reports'
        }
    ]

    const services = [
        {
            id: 'serviceOne',
            icon: 'connect_without_contact',
            title: 'Self Assessment',
            description: 'SignetRisk reports allow an organization to obtain a concise, summary view of the cyber security health of its cloud based assets.  The reports utilize various compliance checks and audits conducted through each cloud service platform to present an aggregated and  streamlined view of cloud security posture'
        },
        {
            id: 'serviceTwo',
            icon: 'query_stats',
            title: 'Cyber Insurance',
            description: 'Consistent cloud cyber health reports for each cloud service platform allow this information to be utilized as a part of cyber insurance applications. The reports emphasize the extent to which an applicant conforms with best common practices and common cyber security standards in their cloud asset operations.'
        },
        {
            id: 'serviceThree',
            icon: 'sync_lock',
            title: 'Vendor Cyber Health',
            description: 'SignetRisk reports allow vendors to share relevant, high level aggregate summary of their cloud cyber health without needing to provide intrusive access to sensitive assets. The reports allow a vendor to demonstrate its compliance with best common practices for each cloud service platform in terms of cyber security.'
        }
    ]

    return(
        <div>
          <div className="gradient">
            <NavBar />
              <Carousel style={{position: 'relative', zIndex: 1, marginTop: '-100px'}}>
                  {slides.map(slide => (
                      <Carousel.Item key={slide.id}>
                          <Carousel.Caption className="carousel-slide text-start px-5 align-items-start">
                              <h1>{slide.heading}</h1>
                              <p>{slide.caption}</p>
                          </Carousel.Caption>
                      </Carousel.Item>
                  ))}
              </Carousel>
          </div>
          
          <div className="container" style={{paddingTop: '40px'}}>
              <div className="row">
                  {services.map(service => (
                      <div key={service.id} className="col-lg-4">
                          <div className="tile">
                              <span className="material-icons">{service.icon}</span>
                              <h2 className="tile-title">{service.title}</h2>
                              <p className="tile-description">{service.description}</p>
                          </div>
                      </div>
                  ))}
              </div>
          </div>

          <div className="marketing bg-color-5">
              <div className="container">
                  <div className="row featurette">
                  <div className="col-md-8">
                      <h2 className="featurette-heading color-1">Streamlined secure access to cloud health summary reports. <span className="color-4">Isolated role-based authorization.</span></h2>
                      <p className="lead" style={{color: 'white'}}>A distinct role with limited capabilities is used to facilitate ready only access to cloud cyber health data reports for each cloud platform.</p>
                      
                  </div>
                  <div className="col-md-4" style={{textAlign: 'center'}}>
                      <span className="material-icons icon-xl color-1">settings_suggest</span>
                  </div>
                  </div>
              </div>
          </div>

          <div className="marketing">
              <div className="container">
                  <div className="row featurette">
                      <div className="col-md-8 order-md-2">
                          <h2 className="featurette-heading color-7">Simplified straightforward data summarization. <span className="text-muted">Un-entangle cyber security compliance checks.</span></h2>
                          <p className="lead">Concise and understandable summarization of dozens of cyber security standard compliance checks and audit results. </p>
                          <p><a className="btn btn-primary" target="_blank" href="assets/SignetRisk-Sample-Report.pdf">Download Sample Report</a></p>
                      </div>
                      <div className="col-md-4 order-md-1" style={{textAlign: 'center'}}>
                          <span className="material-icons icon-xl color-6">new_releases</span>
                      </div>
                  </div>
              </div>
          </div>

          <div className="marketing" style={{background: '#dedede'}}>
              <div className="container">
                  <div className="row featurette">
                      <div className="col-md-8">
                          <h2 className="featurette-heading color-7">Transparent and shareable. <span className="text-muted">Discuss facts not opinions</span></h2>
                          <p className="lead">The purpose of these summary reports is to allow users to share cyber security health information with others in a transparent manner without fear of exposing unnecessary details.  Only aggregate summary/status information is included.</p>
                      </div>
                      <div className="col-md-4 order-md-1" style={{textAlign: 'center'}}>
                          <span className="material-icons icon-xl color-2">water_drop</span>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    )
}