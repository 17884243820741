import React from "react";
import NavBar from "../nav-bar/Nav";

export default function About(){
  return (
    <div>
      <NavBar />
      <div className="container padded-container">
        <div className="pricing-header px-3 py-3 pb-md-4 mx-auto text-center">
          <h1 className="display-4">About</h1>
          <p className="lead">Our goal at SignetRisk is to help organizations big and small to make the most of the cyber security information that they already have acces to. In order to be useful on a day to day basis, this information must be presented in a strightforward manner, and be easy to consume and discuss at all levels of the organization.  </p>
        </div>
      </div>
    </div>
  )
}