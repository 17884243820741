import {React} from "react";
import { Table } from "react-bootstrap";
import './pricing.scss';
import NavBar from "../nav-bar/Nav";

export default function Pricing(){
    const site = process.env.REACT_APP_SITE || 'https://app.signetrisk.com';
    const plans = [
        {
            id: 'enterpriseBasic',
            name: 'Enterprise Basic',
            price: '$24.95/mo',
            discoveryReports: true,
            cloudSecReports: false,
            updates: 'Monthly',
            downloadPrintShare: true,
            multiProviders: false,
            api: false,
            consolidatedReports: false,
            companyWebReports: true,
            route: `${site}/signup?product=Enterprise`
        },
        {
            id: 'planTwo',
            name: 'Enterprise Premium',
            price: '$24.95/mo + $19.95/mo per integration',
            discoveryReports: true,
            cloudSecReports: true,
            updates: 'Daily',
            downloadPrintShare: true,
            multiProviders: true,
            api: false,
            consolidatedReports: true,
            companyWebReports: true,
            route: `${site}/signup?product=Enterprise`
        },
        {
            id: 'planThree',
            name: 'Portfolio Risk Manager',
            price: '$49.99/mo per company (10 minimum)',
            discoveryReports: true,
            cloudSecReports: true,
            updates: 'Daily',
            downloadPrintShare: true,
            multiProviders: true,
            api: true,
            consolidatedReports: true,
            companyWebReports: true,
            route: `${site}/signup?product=Portfolio`
        },
        // {
        //     id: 'planFour',
        //     name: 'Platform',
        //     price: 'Contact us for custom pricing',
        //     discoveryReports: true,
        //     cloudSecReports: true,
        //     updates: 'Daily',
        //     downloadPrintShare: true,
        //     multiProviders: true,
        //     api: true,
        //     consolidatedReports: true,
        //     companyWebReports: true,
        //     route: `${site}/signup?product=Platform`
        // }
    ]
    const handlePlanCheckout = (route) => {
      window.location.href = route
    }

    return(
      <div>
        <NavBar />
        <div className="container padded-container">
          <div className="pricing-header px-3 py-3 pb-md-4 mx-auto text-center">
            <h1 className="display-4">Pricing</h1>
            <p className="lead">Get started with 1 day of access to Enterprise Basic for free. All subscriptions are billed monthly in advance and you can cancel at any time. Our plans are designed to be flexible to allow our clients to choose the right level of access for their needs.</p>
          </div>
          <Table className="comparison-table">
            <thead className="comparison-table-header">
                <tr>
                    <th></th>
                    {plans.map(plan => (
                        <th className={plan.id === 'planThree' ? "comparison-table-plans featured-plan plan-header" : "comparison-table-plans"}>
                            <h5>{plan.name}</h5><p>{plan.price}</p>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody style={{fontSize: '16px'}}>
              <tr>
                <th>Web Discovery Report</th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? 'featured-plan-cell table-bg--gray' : ''}>
                    <span className='material-icons'>{plan.discoveryReports ? 'done' : 'remove'}</span>
                  </td>
                ))}
              </tr>
              <tr>
                <th>Cloud Security Posture Reports</th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? 'featured-plan-cell table-bg--gray' : ''}>
                    <span className='material-icons'>{plan.cloudSecReports ? 'done' : 'remove'}</span>
                  </td>
                ))}
              </tr>
              <tr>
                <th>Update Frequency </th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? 'featured-plan-cell table-bg--gray' : ''}>{plan.updates}</td>
                ))}
              </tr>
              <tr>
                <th>Download/Print/Share</th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? 'featured-plan-cell table-bg--gray' : ''}>
                    <span className='material-icons'>{plan.downloadPrintShare ? 'done' : 'remove'}</span>
                  </td>
                ))}
              </tr>
              <tr>
                <th>Multiple Cloud Providers</th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? 'featured-plan-cell table-bg--gray' : ''}>
                    <span className='material-icons'>{plan.multiProviders ? 'done' : 'remove'}</span>
                  </td>
                ))}
              </tr>
              <tr>
                <th>API Support</th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? 'featured-plan-cell table-bg--gray' : ''}>
                    <span className='material-icons'>{plan.api ? 'done' : 'remove'}</span>
                  </td>
                ))}
              </tr>
              <tr>
                <th>Consolidated Reports<br></br><i>(Coming Soon...)</i></th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? 'featured-plan-cell table-bg--gray' : ''}>
                    <span className='material-icons'>{plan.consolidatedReports ? 'done' : 'remove'}</span>
                  </td>
                ))}
              </tr>
              <tr>
                <th>Company Web Discovery Report<br></br></th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? 'featured-plan-cell table-bg--gray' : ''}>
                    <span className='material-icons'>{plan.companyWebReports ? 'done' : 'remove'}</span>
                  </td>
                ))}
              </tr>
              <tr>
                <th></th>
                {plans.map(plan => (
                  <td className={plan.id === 'planThree' ? "featured-plan-cell last table-bg--gray" : ''}>
                    <button onClick={(e) => {
                      e.preventDefault();
                      handlePlanCheckout(plan.route)}} 
                      className="btn btn-primary btn-block w-100">Select</button>
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    )
}