import React from 'react';
import './style/app.scss';
import About from './components/about/About';
import FAQ from './components/faq/FAQ';
import Landing from './components/landing-page/Landing';
import Pricing from './components/pricing/Pricing';
import { Route, Routes, Link, Outlet, Navigate } from 'react-router-dom';


function App() {
    return (
      <div className='site'>
        <main style={{flex: 1}}>
            <app-toast aria-live="polite" aria-atomic="true"></app-toast>
            <Routes>
              <Route path='/' element={<Landing />}/>
              <Route path='/about' element={<About />}/>
              <Route path='/faq' element={<FAQ />}/>
              <Route path='/pricing' element={<Pricing />}/>
              <Route path="*" element={<Navigate to="/" />}/>
            </Routes>
            <Outlet />
        </main>
        <div className='footer bg-color-7'>
            <div className="container color-4 py-4">
                <div className="links">
                    <a className="btn btn-link" href="assets/SignetRisk-Privacy.pdf" target="_blank">Privacy</a>|
                    <a className="btn btn-link" href="assets/SignetRisk-TermsConditions.pdf" target="_blank">Terms</a>|
                    <a className="btn btn-link" href="mailto:support@signetrisk.com">support@signetrisk.com</a>
                </div>
                <div className="navbar-brand" style={{display: 'flex', padding: '15px 0px'}}>
                    <Link to="/" style={{textDecoration: 'none', display: 'flex', fontFamily: 'sans-serifs'}}>
                      <img src="assets/logo-color-4.png" style={{ height: '40px'}} />
                    </Link>
                </div>
                <p>Copyright &copy; 2024 SignetRisk Analytics, Inc.</p>
            </div>
        </div>
    </div>
    );
}
  
export default App;
