import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import './nav-bar.scss'

export default function NavBar() {
  const site = process.env.REACT_APP_SITE || 'https://app.signetrisk.com';
  const handleLoginClick = (e) => {
    e.preventDefault();
    window.location.href=`${site}/login`;
  };

  const handleSignupClick = (e) => {
    e.preventDefault();
    window.location.href=`${site}/signup`;
  }; 

  return(
    <Navbar expand="md" className="navbar navbar-dark" style={{position: 'relative', zIndex: 2}}>
      <Container style={{padding: '10px 0px',}}>
        <Link to="/" style={{textDecoration: 'none'}}>
          <Navbar.Brand style={{display: 'flex', padding: '15px 0px', fontFamily: 'sans-serifs'}}>
              <img src="assets/logo-color-3.png" style={{ height: '40px'}} />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle className="navbar-toggler" type="button" aria-controls="basic-navbar-nav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse className="collapse navbar-collapse" id="basic-navbar-nav">
          <Nav className="navbar-nav me-auto mb-2 mb-md-0" style={{padding: '10px 0px', margin: 'auto'}}>
            <Link className="nav-route" to="/pricing">Pricing</Link>
            <Link className="nav-route" to="/faq">FAQ</Link>
            <Link className="nav-route" to="/about">About</Link>
          </Nav>
          <form className="d-flex">
            <button onClick={handleLoginClick} className="btn btn-link" style={{marginRight: '5px'}}>Login</button>
            <button onClick={handleSignupClick} className="btn btn-secondary">Signup</button>
          </form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}