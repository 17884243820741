import React from "react";
import NavBar from "../nav-bar/Nav";
import { Accordion } from "react-bootstrap";
import './faq.scss'

export default function FAQ(){
    const questions = [
        {
            id: 'collapseOne',
            question: 'How does this work?',
            answer: `SignetRisk utilizes delegated read-only limited access credentials that you provide to fetch and present information from your existing cloud provider accounts. These API keys must be configured with a restricted role account that limits access in scope to be read only and is restricted to only the security compliance data in your cloud provider account. For example, for AWS, you would need to create an account that would have read-only access to the AWS Security Hub service and configure these keys in your SignetRisk account. SignetRisk would then fetch the appropriate data elements and compile them into a summary report.`
        },
        {
            id: 'collapseTwo',
            question: 'What cloud service providers do you currently support?',
            answer: `The service currently only supports reports based on AWS Security Hub data, though we have plans to extend this to Google Cloud Platform and Microsoft Azure security services as well.`
        },
        {
            id: 'collapseThree',
            question: 'What cloud compliance standards are supported?',
            answer: `The SignetRisk report currently only includes support for AWS Security Hub based compliance tests. The three standards that are included in the SinetRisk report are: "CIS AWS Foundations Benchmark", "AWS Foundational Security Best Practices", and "PCI DSS" .`
        },
        {
            id: 'collapseFour',
            question: 'How do I configure my AWS account to use these reports',
            answer: `We are happy to work with you to get you started.  We provide a simple python script that does all the work.  You are welcome to audit or modify the script as you like.  Just be sure to allow our AWS account to be linked to your Security Hub service.`
        },
        {
            id: 'collapseFive',
            question: 'What roles/permissions do I need to create for SignetRisk reports?',
            answer: `In order to get started you need to use an access role that has the ability to create IAM roles in your AWS account.  The python script that we provide will help you get started by creating a new role in your account that will link your AWS Security Hub information to our AWS data connector.  One this is in place we will have access to just the Security Hub information in your AWS account and nothing else.  There is no other access that is needed other than this.`
        },
        {
            id: 'collapseSix',
            question: 'Do you report on data for all regions of AWS?',
            answer: `Yes.  You can configure reporting on multiple regions via the setup script that we provide.`
        }
    ];

    return(
      <div>
        <NavBar />
        <div className="container padded-container">
            <div className="pricing-header px-3 py-3 pb-md-4 mx-auto text-center">
                <h1 className="display-4">FAQ</h1>
                <p className="lead">Confused?  It is likely you are not alone.  Please feel free to browse the list of frequently asked questions below to see if we can help shed some light on your questions or concerns.  If you still have questions, please reach out to us via email at support@signetrisk.com </p>
            </div>
            <div>
                <Accordion defaultActiveKey={["0"]} >
                    {questions.map(question => (
                        <Accordion.Item eventKey={questions.indexOf(question)}>
                            <Accordion.Header>{question.question}</Accordion.Header>
                            <Accordion.Body>
                                {question.answer}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </div>
      </div>
    )
}